<template>
  <navigation index="productServer" ref="navigation">
    <div
      class="topnavigation"
      style="gap: 10px; justify-content: space-between"
    >
      <el-button
        type="primary"
        @click="
          OpenProductServiceWindow({
            Name: '',
            MnemonicCode: '',
            Price: 0,
            Code: '',
            IncludeTax: false,
            OldPrice: 0,
            Sort: 0,
            Images: '',
            Description: '',
            Cost: 0,
            MnemonicCode: '',
            TaxRate: 10,
          })
        "
      >
        {{ tools.GetLanguageValue("web.新增") }}</el-button
      >
    </div>

    <div style="width: 100%">
      <el-table
        border
        :data="ProductServerDatas"
        :height="ContentHeight + 'px'"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          :label="tools.GetLanguageValue('web.商品名称')"
          width="180"
          prop="Name"
        />
        <el-table-column
          label="商品类型"
          width="180"
          prop="ProductTypeFormat"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.编码')"
          width="180"
          prop="Code"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.单价')"
          width="80"
          prop="Price"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.成本价')"
          width="80"
          prop="Cost"
        />
        <!-- <el-table-column
          :label="tools.GetLanguageValue('web.税率')"
          width="80"
          prop="TaxRate"
        /> -->
        <el-table-column
          :label="tools.GetLanguageValue('web.助记码')"
          width="180"
          prop="MnemonicCode"
        />

        <el-table-column
          :label="tools.GetLanguageValue('web.操作')"
          fixed="right"
          width="220"
        >
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="OpenProductServiceWindow(scope.row)"
            >
              {{ tools.GetLanguageValue("web.编辑") }}</el-button
            >
            <el-button
              size="small"
              @click="DeleteProductService(scope.row)"
              type="danger"
            >
              {{ tools.GetLanguageValue("web.删除") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="margin: 5px">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="GetGoods"
          :page-size="ProductServerPadding.Size"
          :total="ProductServerPadding.Count"
        >
        </el-pagination>
      </div>
    </div>
  </navigation>

  <!-- 添加 -->
  <el-dialog
    v-model="ProductServiceWindow"
    :title="tools.GetLanguageValue('web.新增服务')"
    width="60%"
    :show-close="false"
  >
    <el-tabs v-model="LanguageName">
      <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
        <el-form
          ref="form"
          :model="ProductServerOpt"
          label-width="80px"
          style="margin-top: 10px"
        >
          <el-row>
            <el-col :span="10">
              <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
                <el-input
                  v-model="ProductServerOpt.Name"
                  :placeholder="tools.GetLanguageValue('web.请输入商品名称')"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.助记码')">
                <el-input
                  v-model="ProductServerOpt.MnemonicCode"
                  :placeholder="tools.GetLanguageValue('web.请输入助记码')"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.编号')">
                <el-input
                  v-model="ProductServerOpt.Code"
                  :placeholder="tools.GetLanguageValue('web.请输入编号')"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.单价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.Price"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.成本价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.Cost"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4"> </el-col>
            <el-col :span="10">
              <el-form-item :label="tools.GetLanguageValue('web.原价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.OldPrice"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.排序')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.Sort"
                ></el-input-number>
              </el-form-item>
              <!-- <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
                <el-switch
                  v-model="ProductServerOpt.IncludeTax"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.税率')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="ProductServerOpt.TaxRate"
                  :max="100"
                  :min="0"
                ></el-input-number>
              </el-form-item> -->
              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input
                  :placeholder="tools.GetLanguageValue('web.请输入备注')"
                  v-model="ProductServerOpt.Description"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>

      <el-tab-pane
          :label="item.LanguageName"
          :name="item.LanguageName"
          v-for="(item, index) in ProductServerOpt.Languages"
          :key="index"
        >
          <el-form-item :label="tools.GetLanguageValue('web.名称')">
            <el-input
              v-model="item.Language.Name"
              @focus="InputSelect"
            ></el-input>
          </el-form-item>

        
        </el-tab-pane>
    </el-tabs>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ProductServiceWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="AddProductService" type="primary">
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "../../../../commons/AxiosMethod.js";
import tools from "../../../../commons/tools.js";

import { ElMessageBox, ElMessage } from "element-plus";
import ProductItem from "../../../../components/ProductItem.vue";
import navigation from "../../../../components/Modules/Navigation.vue";
export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      GenerateGoodsProductSkuWindow: false,
      ProductServerOpt: {},
      ProductServiceWindow: false,
      ProductServerDatas: [],
      ProductServerPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      GoodsPictureOpt: {}, //图片
      Categorys: [],
      ProductTypes: [],

      AttributeGroup: [], //属性组

      CheckboxProductAttribute: [],

      ProductSKU: [], //所包含的所有SKU 是生成的 ，还没有保存
      GoodsPrducts: [],
      ContentHeight: document.documentElement.clientHeight - 120,
      tools: tools,
      LanguageName: "first",
    };
  },
  name: "App",
  mounted() {
    this.GetProductService();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 120;
  },

  components: {
    navigation,
    ProductItem,
  },
  methods: {
    InputSelect(e) {
      e.currentTarget.select();
    },
    //删除SKU
    DeleteProductService(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/DeleteProductService",
            "get",
            {
              goodId: item.GoodsId,
            },
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetProductService();
              } else {
                ElMessageBox({
                  title: "提示",
                  message: "设置失败",
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },

    //打开编辑窗口
    OpenProductServiceWindow(item) {
      axios.apiMethod(
        "/stores/GoodsManage/GetProductServerDetail",
        "get",
        {
          productId: item.Id,
        },
        (result) => {
          this.ProductServerOpt = result.Data;
          this.ProductServiceWindow = true;
        }
      );

     
    },

    AddProductService() {
      axios.apiMethod(
        "/stores/GoodsManage/AddProductService",
        "post",
        this.ProductServerOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetProductService();
            this.ProductServiceWindow = false;
          } else {
            ElMessage({
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },

    // 获取商品
    GetProductService() {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetProductService",
        "post",
        this.ProductServerPadding,
        (result) => {
          this.ProductServerDatas = result.Data.Datas;
          this.ProductServerPadding.Page = result.Data.Page;
          this.ProductServerPadding.Size = result.Data.Size;
          this.ProductServerPadding.Count = result.Data.Count;
        }
      );
    },
  },
};
</script>
  
<style scoped lang="less">
.inputSKU {
  width: 100%;
  border: 0px;
}

.tableSKU {
  tr {
    td {
      padding: 5px;
      height: 40px;
    }
  }
}
</style>
  